import React, { useEffect, useState } from "react";
import { Table, Form, Input, Button, message, Modal } from "antd";
import api from "../../api";
import qs from "qs";
import "./tabel.less";

// 创建 EditableCell 组件
const EditableCell = ({ text, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onEdit(inputValue);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div onDoubleClick={handleDoubleClick} style={{ cursor: "pointer" }}>
      {isEditing ? (
        <Input
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        text
      )}
    </div>
  );
};

// 创建 EditableObjectCell 组件
const EditableObjectCell = ({ text, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(JSON.stringify(text, null, 2));

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    try {
      const parsedValue = JSON.parse(inputValue);
      onEdit(parsedValue);
    } catch (e) {
      message.error("请输入有效的 JSON 格式");
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    try {
      JSON.parse(newValue); // 尝试解析用户输入
      setInputValue(newValue); // 如果解析成功，更新状态
    } catch (error) {
      message.error("请输入有效的 JSON 格式");
    }
  };

  return (
    <div onDoubleClick={handleDoubleClick} style={{ cursor: "pointer" }}>
      {isEditing ? (
        <Input.TextArea
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          rows={5}
        />
      ) : (
        <div>
          {text.map((item) => (
            <div key={item.label}>
              {item.label}: {item.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Quotation = () => {
  const [loading, setLoading] = useState(false);
  const [quotationInfo, setquotationInfo] = useState({});
  const [proDataSource, setProDataSource] = useState([]);
  const [totalPrice, setTotalPrice] = useState("0.00");

  const [form] = Form.useForm();

  const [remark, setRemark] = useState("");
  const [quotationDesc1, setQuotationDesc1] = useState("");
  const [quotationDesc2, setQuotationDesc2] = useState("");
  const [quotationDesc3, setQuotationDesc3] = useState("");
  const [quotationDesc4, setQuotationDesc4] = useState("");
  const [quotationDesc5, setQuotationDesc5] = useState("");
  const [quotationDesc6, setQuotationDesc6] = useState("");

  const [editingRowKey, setEditingRowKey] = useState(null);

  const handleEdit = (record, dataIndex, value) => {
    const newData = proDataSource.map((item) => {
      if (item.key === record.key) {
        return {
          ...item,
          [dataIndex]: JSON.stringify(value),
        };
      }
      return item;
    });

    setProDataSource(newData);
    calculateTotalPrice(newData);
  };

  const calculateTotalPrice = (dataSource) => {
    let totalPrice = 0;
    dataSource.forEach((e) => {
      totalPrice += (e.salesAmount || 0) * (e.productNumber || 0);
    });
    setTotalPrice(totalPrice.toFixed(2));
  };

  const proColumns = [
    {
      title: "项次",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return index + 1;
      },
    },
    {
      title: "产品名称",
      dataIndex: "productTitle",
      width: 200,
      render: (text, record) => (
        <EditableCell
          text={text}
          onEdit={(value) => handleEdit(record, "productTitle", value)}
        />
      ),
    },
    {
      title: "品种规格",
      dataIndex: "productConfig",
      render: (text, record) => {
        console.log("Raw productConfig:", record.productConfig); // 输出原始值
        const productConfig = record.productConfig
          ? JSON.parse(record.productConfig)
          : [];
        console.log("Parsed productConfig:", productConfig); // 输出解析后的值
        return (
          <EditableObjectCell
            text={productConfig}
            onEdit={(value) => handleEdit(record, "productConfig", value)}
          />
        );
      },
    },
    {
      title: "数量",
      dataIndex: "productNumber",
      width: 100,
      align: "center",
      render: (text, record) => (
        <EditableCell
          text={text ? text + "PCS" : "-"}
          onEdit={(value) =>
            handleEdit(record, "productNumber", parseFloat(value))
          }
        />
      ),
    },
    {
      title: "单价（元）",
      dataIndex: "salesAmount",
      width: 120,
      align: "center",
      render: (text, record) => (
        <EditableCell
          text={text ? text.toFixed(2) : "-"}
          onEdit={(value) =>
            handleEdit(record, "salesAmount", parseFloat(value))
          }
        />
      ),
    },
    {
      title: "总金额（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, record) => {
        const total = (record.salesAmount || 0) * (record.productNumber || 0);
        return total.toFixed(2);
      },
    },
  ];

  const getQuotationInfo = async () => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let res = await api.orderGetQuotation({ orderCode });
    form.setFieldsValue({
      ...res.data,
    });
    let totalPrice = 0;
    res.data.orderProductList.forEach((e) => {
      totalPrice += e.salesAmount * e.productNumber;
    });
    setTotalPrice(totalPrice.toFixed(2));
    setquotationInfo(res.data);
    setProDataSource(
      res.data.orderProductList.map((item, index) => {
        let productConfig = item.productConfig;
        if (typeof productConfig === "string") {
          productConfig = productConfig.replace(/^"|"$/g, "").replace(/）/g, ")");
        }
        return {
          ...item,
          productConfig: productConfig,
          key: index,
        };
      })
    );
    setRemark(res.data.remark || "");
    setQuotationDesc1(res.data.quotationDesc1 || "");
    setQuotationDesc2(res.data.quotationDesc2 || "");
    setQuotationDesc3(res.data.quotationDesc3 || "");
    setQuotationDesc4(res.data.quotationDesc4 || "");
    setQuotationDesc5(res.data.quotationDesc5 || "");
    setQuotationDesc6(res.data.quotationDesc6 || "");
  };

  useEffect(() => {
    getQuotationInfo();
  }, []);

  const onFinish = async (values) => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let params = {
      id: quotationInfo.id,
      ...values,
      orderCode,
      remark,
      quotationDesc1,
      quotationDesc2,
      quotationDesc3,
      quotationDesc4,
      quotationDesc5,
      quotationDesc6,
      orderProductList: proDataSource.map((item) => ({
        ...item,
        productConfig: JSON.stringify(item.productConfig),
      })),
    };
    let res = await api.orderPutQuotation(params);

    if (res.code == 200) {
      api
        .quotationExport({
          orderCode: qs.parse(window.location.search.split("?")[1]).orderCode,
        })
        .then((res) => {
          if (res.code == 200) {
            let btn = document.createElement("a");
            btn.download = `订单编号-${
              qs.parse(window.location.search.split("?")[1]).orderCode
            }-报价单.pdf`;
            btn.href = res.data;
            btn.target = "_blank";
            btn.click();
            btn = null;
          }
        });
      return message.success("保存成功,正在生成单据");
    }
    message.error(res.message);
  };

  return (
    <div style={{ width: "60%", margin: "0 auto" }}>
      <Form name="quotation" form={form} initialValues={{}} onFinish={onFinish}>
        <h2 className="text-center">产品报价单</h2>
        <br />
        <br />
        <div className="ant-table ant-table-bordered">
          <div className="ant-table-container">
            <div className="ant-table-content">
              <table className="table">
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">客户名称：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="custName" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">报价编号：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="quotationNo" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">地址：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="addr" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">报价日期：</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="quotationDate"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">联系人：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="contacts1" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">联系人：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="contacts2" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">电话：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="tel1" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">电话：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="tel2" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">传真：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="fax1" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">传真：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="fax2" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
        <br />
        <p>产品名称、型号、数量及价款：</p>
        <Table
          columns={proColumns}
          dataSource={proDataSource}
          pagination={false}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <EditableCell
                    text={remark}
                    onEdit={(value) => setRemark(value)}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <b />
                    <b>RMB：{totalPrice}</b>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={1} rowSpan={4}>
                  <b>报价说明</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <EditableCell
                    text={quotationDesc1}
                    onEdit={(value) => setQuotationDesc1(value)}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}>
                  <EditableCell
                    text={quotationDesc2}
                    onEdit={(value) => setQuotationDesc2(value)}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <EditableCell
                    text={quotationDesc3}
                    onEdit={(value) => setQuotationDesc3(value)}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}>
                  <EditableCell
                    text={quotationDesc4}
                    onEdit={(value) => setQuotationDesc4(value)}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <EditableCell
                    text={quotationDesc5}
                    onEdit={(value) => setQuotationDesc5(value)}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}>
                  <EditableCell
                    text={quotationDesc6}
                    onEdit={(value) => setQuotationDesc6(value)}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <br />
        <br />
        <div className="text-center">
          <Button
            className="mr-3"
            onClick={() => {
              window.close();
            }}
          >
            返回
          </Button>
          <Button className="mr-3" htmlType="submit" type="primary">
            提交
          </Button>
          <Button
            className="mr-3"
            onClick={() => {
              api
                .quotationExport({
                  orderCode: qs.parse(window.location.search.split("?")[1])
                    .orderCode,
                })
                .then((res) => {
                  if (res.code == 200) {
                    let btn = document.createElement("a");
                    btn.download = `订单编号-${
                      qs.parse(window.location.search.split("?")[1]).orderCode
                    }-报价单.pdf`;
                    btn.href = res.data;
                    btn.target = "_blank";
                    btn.click();
                    btn = null;
                  }
                });
            }}
          >
            导出
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Quotation;
